var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "p-5" },
    [
      _c("h1", { staticClass: "mb-5" }, [
        _vm._v("The MarketSignals™ Prescriptive Data Analysis Summary")
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("h2", [_vm._v("Customer File Audit Counts")]),
      _vm._v(" "),
      _c("chart-grid", {
        attrs: {
          "chart-wrapper-class": "col-lg-6",
          charts: _vm.chartData.fileAnalysis
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c(
            "div",
            { staticClass: "mb-0", class: _vm.filterAlertClass },
            [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.filterStatusMessage) }
              }),
              _vm._v("\n                Adjust this in "),
              _c(
                "router-link",
                {
                  staticClass: "alert-link",
                  attrs: { to: { params: { tab: "settings", section: null } } }
                },
                [_vm._v("Settings")]
              ),
              _vm._v(".\n            ")
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("chart-grid", {
        attrs: {
          "chart-wrapper-class": "col-lg-6",
          charts: _vm.chartData.overviewData
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "newspaper-columns mb-5", attrs: { columns: "2" } },
      [
        _c("p", [
          _vm._v(
            "To create this report, MarketSignals demographic, lifestyle, interest, and proprietary predictive data was\n            first appended to your customer file through our MarketSignals data enhancement process. The enhancement\n            process is performed by passing the consumer records on the file through the Wiland Identity system to\n            resolve the identities to our known consumers, then each consumer is joined with the available data for\n            them. To complete the reporting process each individual consumer is compared to our national baseline to\n            establish a data driven profile of the overall customer file."
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "The objective of this report is to reveal the most important characteristics of your customers. Each page\n            includes an analysis of your customers highlighting the characteristic values that are overrepresented\n            and those that are underrepresented compared to the average US consumer. These insights are valuable to\n            your personalization and segmentation efforts, for identifying key personas present in your file, and to\n            help you pick out the MarketSignals data that would be most valuable to you."
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Each tab on this report contains a different type of data. The first dropdown will summarize the overall\n            category as it was represented on your file, and each of the remaining pages available by the dropdown\n            menuitem will contain the details of every data element. These characteristics are visualized via graphs\n            and tables of the key statistics, including index values and the quantity of your file associated with\n            each value. For continuous data elements we bin the values into deciles, and for those data elements we\n            also include the median value in the table associated with each data element."
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "The index value, associated with each characteristic, is a statistical comparison of the percent of\n            households in the customer file possessing a specific characteristic, and the percent of households in\n            the reference population possessing that same characteristic. Index values greater than 1 indicate\n            characteristics that are over-represented, and index values less than 1 indicate characteristics that\n            are under-represented."
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col" }, [
      _c("h2", [_vm._v("Your Data at a Glance")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }