
    import {Vue, Component, Prop} from 'vue-property-decorator';
    // import Highcharts from 'highcharts';
    // import highchartsMore from 'highcharts/highcharts-more';
    // import {Chart} from 'highcharts-vue';
    import * as Utils from 'Utilities/utils';
    import FontAwesomeIcon from 'Components/common/font-awesome-icon';
    // import ChartGrid from "Components/pda/chart-grid.vue";
    import ChartAssociatedData from 'Components/pda/chart-associated-data';

    // highchartsMore(Highcharts);

    @Component<ExportCart>({
        components: {
            ChartAssociatedData,
            // ChartGrid,
            // highcharts: Chart,
            FontAwesomeIcon,
        }
    })
    export default class ExportCart extends Vue {
        // @Prop({
        //     type: Object,
        //     default: () => {
        //         return {};
        //     }
        // })
        // chartData: any;

        @Prop({
            type: Array,
            default: () => {
                return [];
            }
        })
        detail;

        // @Prop({
        //     type: Object,
        //     default: () => {
        //     }
        // })
        // params;

        Utils = Utils;

        confirmDeleteAllCartItems() {
            const confirmed = confirm('This will remove all items from your MarketSignals order. Do you wish to continue?');
            if (confirmed) {
                this.$emit('delete-all-cart-items');
            }
        }

        deleteCartItem(detailItem: any) {
            this.$emit('delete-cart-item', detailItem);
        }

        excelExport() {
            this.$emit('excel-export');
        }

        formatExportData(detailItem: any) {
            return {
                // format: 'table',
                detail: [detailItem.table],
            }
        }

        prepareForOrder() {
            this.$emit('prepare-for-order');
        }

    }
