
    import {Vue, Component, Watch} from 'vue-property-decorator';
    import VueMeta from 'vue-meta'
    import VueRouter from 'vue-router';
    import BootstrapVue from 'bootstrap-vue';
    import 'bootstrap';
    import store from 'Store/store';
    import Navigation from 'Components/app/navigation/navigation';
    import Spinner from "Common/spinner";
    import AppError from 'Components/app/error';
    import LandingPage from 'Components/landing-page';
    import PdaReport from 'Components/pda/pda-report';

    import {uuidPattern} from 'Server/api';
    import {Status} from 'Utilities/immutables';

    Vue.use(BootstrapVue);
    Vue.use(VueMeta, {
        refreshOnceOnNavigation: true
    });
    Vue.use(VueRouter);

    const defaultTitle = 'MarketSignals™';
    const tokenPattern = `:token(${uuidPattern})`;
    const routes = [
        {
            path: '/',
            name: 'landing',
            component: LandingPage,
            meta: {
                requiresAuth: false,
                requiresShareKey: false,
            },
        },
        // {
        //     path: `/pda-report/${tokenPattern}/pdf`,
        //     name: 'pdaReportPrintable',
        //     component: PdaReport,
        //     meta: {
        //         requiresAuth: false,
        //         // requiresShareKey: true,
        //         requiresShareKey: false,
        //     },
        // },
        {
            path: `/pda-report/${tokenPattern}/:tab?/:section?`,
            name: 'pdaReport',
            component: PdaReport,
            meta: {
                requiresAuth: false,
                // requiresShareKey: true,
                requiresShareKey: false,
            },
        },

        {
            // Fall back to a 404 error page
            path: '/:catchAll(.*)',
            name: 'error404',
            component: AppError,
        },
    ];
    const router = new VueRouter({
        mode: 'history',
        // linkActiveClass: 'active',
        routes, // short for routes: routes
        scrollBehavior: function (to, from, savedPosition) {
            if (to.hash) {
                // return { selector: to.hash };
                // use scrollIntoView for anchor tags for now since return selector not working
                Vue.nextTick(() => {
                    return document.getElementById(to.hash.substring(1))?.scrollIntoView({behavior: 'smooth'});
                })
            } else {
                // Return to top of page on any route change
                return {x: 0, y: 0}
            }
        }
    });

    @Component<App>({
        metaInfo: {
            titleTemplate: (title) => {
                return title ? `${title} | ${defaultTitle}` : defaultTitle;
            },
            htmlAttrs: {
                lang: 'en',
                amp: 'true'
            },
        },
        components: {
            Navigation,
            Spinner,
        },
        router
    })
    export default class App extends Vue {
        appEvent: string = Status.INITIALIZED;
        currentRoute: string = (this.routerHistory && this.routerHistory.current) ? this.routerHistory.current.name : '';
        headerExtra: string = '';
        headerTitle: string = '';
        lastPath = '';
        logoFilename = '';
        routerHistory: any = (router as any).history;

        mounted() {
            let fontawesomeCss = document.createElement('link');
            fontawesomeCss.setAttribute('rel', 'stylesheet');
            fontawesomeCss.setAttribute('href', 'https://pro.fontawesome.com/releases/v5.13.0/css/all.css');
            fontawesomeCss.setAttribute('integrity', 'sha384-IIED/eyOkM6ihtOiQsX2zizxFBphgnv1zbe1bKA+njdFzkr6cDNy16jfIKWu4FNH');
            fontawesomeCss.setAttribute('crossorigin', 'anonymous');
            document.head.appendChild(fontawesomeCss);
        }

        async isAuthenticated() {
            const authenticated: Promise<boolean> = await store.dispatch('isAuthenticated');
            return authenticated;
        }

        get logo() {
            const rootPath = '/assets/images';
            const logoPath = this.logoFilename || ''; // TODO: default logo path?

            return  `${rootPath}/${logoPath}`;
        }

        get navigation() {
            return store.getters.getNavigation;
        }

        get routeKey() {
            let routeKey = this.$route?.name;
            if (this.navigation && this.$route?.meta?.forceRouteRefresh) {
                routeKey = `${routeKey}_${this.navigation.timestamp}`;
            }

            return routeKey;
        }

        get year() {
            return (new Date()).getFullYear();
        }

        setHeaderExtra(extra: string) {
            this.headerExtra = extra;
        }

        setHeaderTitle(title: string) {
            this.headerTitle = title;
        }

        setLogoFilename(filename: string) {
            this.logoFilename = filename;
        }
    }
