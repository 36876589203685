
    import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
    import debounce from 'lodash-es/debounce';
    import * as Utils from 'Utilities/utils';
    import FontAwesomeIcon from 'Components/common/font-awesome-icon';
    import Pagination from 'Common/pagination.vue';
    import {dataElementTypes} from 'Utilities/reports';

    @Component<DataGuide>({
      computed: {
        dataElementTypes() {
          return dataElementTypes
        }
      },
        components: {
            Pagination,
            FontAwesomeIcon,
        }
    })
    export default class DataGuide extends Vue {
        @Prop({
            type: Object,
            default: () => {
                return {};
            }
        })
        chartData: any;

        @Prop({
            type: Object,
            default: () => {
                return {};
            }
        })
        detail: any;

        @Prop({
            type: Object,
            default: () => {
            }
        })
        params: any;

        dataElementSearchInput: string = '';
        dataElementSearchQuery: string = '';
        sortDirection: string = 'asc';
        sortGroup: string = 'dataElementId';
        Utils = Utils;
        // countThresholdValue: number = this.countThreshold?.value || 0;
        // excludeSummaryDataElementsFilter: string = '';
        pagination: any = {
            currentPage: 1,
            itemsPerPage: 25,
            itemCount: 0
        };

        get dataElements() {
            return this.detail.report.stats;
        }

        get filteredDataElements() {
            const searchString = this.dataElementSearchQuery.toLowerCase();
            return this.sortedDataElements
                .filter(
                    (dataElement: any) => searchString.length ?
                        dataElement.dataElementId.toLowerCase().indexOf(searchString) > -1
                        || dataElement.shortDescription.toLowerCase().indexOf(searchString) > -1 :
                        true
                );
        }

        get paginatedDataElements(): any[] {
            const start = (this.pagination.currentPage - 1) * this.pagination.itemsPerPage,
                end = start + this.pagination.itemsPerPage;
            return this.filteredDataElements
                .slice(start, end)
                .map((dataElement: any) =>
                    Object.assign({}, dataElement, {
                        pages: this.dataElementPageMatches(dataElement.dataElementId)
                    })
                );
        }

        get sortedDataElements() {
            return Utils.sortByProperty(this.dataElements, this.sortGroup, this.sortDirection);
        }

        // cartItemMode(dataElement: any) {
        //
        // }
        //
        // cartItemIcon(dataElement: any) {
        //     return ['fad', 'trash'];
        // }
        //
        // cartItemTitle(dataElement: any) {
        //     return `Remove ${dataElement.dataElementId} from your MarketSignals order`;
        // }

        dataElementPageMatches(dataElementId: string) {
            // Find which pages this element is on, if any
            return Utils.dataElementPageLocations(
                this.detail.pages,
                dataElementId,
                Object.assign({}, this.params, {routeName: 'pdaReport'})
            );
        }

        private debounceDataElementSearch = debounce(
            () => this.dataElementSearch(), 500, {}
        );

        dataElementSearch() {
            this.dataElementSearchQuery = this.dataElementSearchInput;
        }

        handleCartItem(params: any) {
            this.$emit('handle-cart-item', params);
        }

        paginateTo(toPage: number) {
            this.pagination.currentPage = toPage;
        }

        paginationSize(itemsPerPage: number) {
            // this.pagination.currentPage = 1;
            this.pagination.itemsPerPage = itemsPerPage;
        }

        sortBy(group: string) {
            if (this.sortGroup === group) {
                // Toggle the sort direction
                this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
            } else {
                this.sortDirection = 'asc';
            }

            this.sortGroup = group;
        }

        sortIcon(property: string) {
            let sortIcon = 'circle';
            if (this.sortGroup === property) {
                sortIcon = this.sortDirection === 'desc' ? 'sort-amount-down' : 'sort-amount-down-alt';
            }

            return ['fas', sortIcon];
        }

        toggleCartItem(dataElement: any) {
            console.log('TOGGLE CART ITEM:', dataElement);
        }

        @Watch('dataElementSearchInput')
        onDataElementSearchInputChanged() {
            this.debounceDataElementSearch();
        }

        @Watch('dataElementSearchQuery')
        onDataElementSearchQueryChanged(newValue) {
            this.paginateTo(1);
        }

        @Watch('filteredDataElements', {immediate: true})
        onDataElementsChanged(newValue: any[], oldValue: any[]) {
            const itemCount = newValue.length,
                previousItemCount = oldValue?.length || 0,
                pageCount = Math.ceil(itemCount / this.pagination.itemsPerPage),
                previousPageCount = Math.ceil(previousItemCount / this.pagination.itemsPerPage);

            // console.log(`
            //     FILTERED EXCLUDED SUMMARY DATA ELEMENTS UPDATED!
            //     (ITEMS PER PAGE: ${this.pagination.excludedSummaryDataElements.itemsPerPage})
            //     - NEW COUNT: ${itemCount} (${pageCount} PAGES)
            //     - OLD COUNT: ${previousItemCount} (${previousPageCount} PAGES)
            // `);

            if (previousPageCount && previousPageCount !== pageCount && this.pagination.currentPage > pageCount) {
                // console.log('--> PAGINATE TO PREVIOUS PAGE...')
                this.paginateTo(pageCount);
            }

            this.pagination.itemCount = itemCount;
        }

        @Watch('paginatedDataElements', {immediate: true})
        onPaginatedDataElementsChanged() {
            // Update the parent data element list
            this.$emit('set-data-element-list', this.paginatedDataElements);
        }
    }
