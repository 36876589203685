
import {Component, Vue, Watch} from 'vue-property-decorator';
import clone from 'lodash-es/clone';
import forOwn from 'lodash-es/forOwn';
import Highcharts from 'highcharts';
import HighchartsCustomEvents from 'highcharts-custom-events';
import HighchartsMap from 'highcharts/modules/map';
import {Chart} from 'highcharts-vue';
import writeXlsxFile from 'write-excel-file'
import FontAwesomeIcon from 'Common/font-awesome-icon';
import Spinner from 'Common/spinner';
import Subnav, {NavElement, NavLink} from 'Components/app/navigation/subnav';
import ChartGrid from 'Components/pda/chart-grid';
import DataGuide from 'Components/pda/custom-layout/data-guide';
import ExportCart from 'Components/pda/custom-layout/export-cart';
import PdaSummary from 'Components/pda/custom-layout/pda-summary';
import Settings from 'Components/pda/custom-layout/settings';
import * as ReportUtils from 'Utilities/reports';
import * as Utils from 'Utilities/utils';
import {dataTableIndicator, tableSubtitleIndicator, tableTitleIndicator} from 'Components/pda/chart-associated-data';
import OnPageHelp from 'Common/on-page-help';
import {isNumeric} from 'Utilities/utils';
import {chartEvents, dataElementTypes} from 'Utilities/reports';

HighchartsCustomEvents(Highcharts);
HighchartsMap(Highcharts);
Highcharts.AST.allowedAttributes.push('data-action', 'data-chart-id', 'data-context', 'data-element-id');
// Highcharts.AST.allowedAttributes.push('data-element-id');

export const sortAlphabeticalElement = {
    group: 'alphabetical',
    // color: 'black',
    color: 'purple',
    label: 'Sort Alphabetically',
};
export const sortIndexElement = {
    group: 'maxIndexScore',
    color: 'green',
    label: 'Sort by Index',
};
export const sortQuantityElement = {
    group: 'relatedOverlayNumberOfIndividuals',
    color: 'blue',
    label: 'Sort by Quantity',
};
// export const sortZScoreElement = {
//     group: 'topBinOverlayZScore',
//     color: 'orange',
//     label: `Sort by ${zScoreDescriptor}`,
// };
export const sortLiftScoreElement = {
    group: 'linearityLiftScore',
    color: 'orange',
    label: `Sort by ${ReportUtils.linearityLiftScoreDescriptor}`,
};

@Component<PdaReport>({
    metaInfo() {
        return {
            bodyAttrs: {
                class: ['bg-secondary']
            },
            title: `Prescriptive Data Analysis Report`,
        };
    },
    components: {
        OnPageHelp,
        highcharts: Chart,
        ChartGrid,
        ExportCart,
        FontAwesomeIcon,
        PdaSummary,
        Spinner,
        Subnav,
    }
})
export default class PdaReport extends Vue {
    allowExcelExport: boolean = false;
    asyncData: any = [];
    chartDataCache: any = {};
    chartListCache: any = {};
    customDataElementList: any[] = [];
    customLayout: any = false;
    dataElementSearchModal: string = 'dataElementSearchModal';
    // dataElementSearchResult: string = '';
    dataElementSearchResult: any[] = [];
    dataElementSearchQuery: string = '';
    error: any = {code: 0, message: ''};
    exportCart: any = {
        localStorageKey: 'pdaExportCart.dataElementIds',
        dataElements: [],
    };
    filterSettings = {
        count: 0
    };
    insights: any = {
        is_data_available: true,
    };
    pageReady = 'Page ready';
    params: any = {
        token: '',
        id: '',
        tab: '',
        section: '',
    };
    ready = false;
    reportData: any = {
        pages: [],
    };
    reportDataReady: boolean = false;
    reportReady: boolean = false;
    settings: any = {
        thresholdCount: {
            max: 10000,
            step: 100,
            value: 100,
        },
        thresholdPercent: {
            max: 25,
            value: 1,
        },
        'excludedDataElements.summary': {
            value: [],
        },
        'filterDataElementType.index': {
            value: false,
        }
    }
    sortGroup: string | null = '';
    subnavTimestamp = new Date().toISOString();

    Utils = Utils;

    async created() {
        window.addEventListener('keydown', this.keyListener);
    }

    async destroyed() {
        window.removeEventListener('keydown', this.keyListener);
    }

    async mounted() {
        this.$emit('set-header-title',
            `<div style="margin-left: 5rem;">MarketSignals&trade; <span class="text-info">Prescriptive Data Analysis Report</span></div>`
        );
        this.$emit('set-logo-filename', `pda-dots-cropped.svg`);

        // this.$wait.start(this.pageReady);
        await this.initialize(true);

        // this.$wait.end(this.pageReady);
        this.ready = this.reportReady;
    }

    async updated() {
        // TODO: why can't we make a getter for this that updates live?
        this.$nextTick(_ => {
            switch (this.$route.params.tab) {
                case 'order':
                    this.allowExcelExport = false;
                    break;

                default:
                    this.allowExcelExport = document.querySelectorAll(`table[data-intent="${dataTableIndicator}"]`).length > 0;
                    break;
            }

            if (this.$route.hash?.length) {
                Utils.scrollToHash(this.$route.hash);
            }
        });
    }

    get chartList(): string[] {
        switch (this.$route.name) {
            case 'reportPDF':
                return ['dma_code', 'metro_area', 'region', 'state'];
        }

        const cacheName = `${this.params.tab}:${this.params.section}`;
        if (this.chartListCache[cacheName]?.length) {
            return this.chartListCache[cacheName];
        }

        if (this.isAutomatedPage) {
            // Dynamically driven via page metadata
            const tab: NavLink = this.currentTab;
            const chartIdPrefix = tab.id;
            switch (this.params.section) {
                case 'summary':
                    return [`${chartIdPrefix}:summary`];

                default: {
                    const section = this.currentSection;
                    if (!section) {
                        return [];
                    }

                    this.chartListCache[cacheName] = Utils.sortByProperty(
                        section.detail.metadata.dataElements,
                        'displaySequence',
                        'asc'
                    )
                        .map((dataElement: any) => `${chartIdPrefix}:${this.params.section}:${dataElement.chartType.toLowerCase()}:${dataElement.dataElementDisplayId}`);

                    return this.chartListCache[cacheName];
                }
            }
        }

        return [];
    }

    get chartListAssociatedData() {
        let data = [];

        for (const chartId of this.chartList) {
            const associatedId = this.chartAssociatedId(chartId);
            if (typeof associatedId === 'string') {
                data.push({
                    chartId,
                    associatedId,
                    data: this.chartAssociatedData(associatedId),
                });
            }
        }

        return data;
    }

    get chartListData() {
        if (!this.chartList?.length) {
            return [];
        }

        let chartListData: any[] = this.chartList.map(chartId => {
            return {
                chartId,
                data: this.chartData(chartId),
            }
        });

        if (this.isAutomatedPage) {
            // Dynamically driven via page metadata
            switch (this.params.section) {
                case 'summary':
                    break;

                default: {
                    const sortDirection = this.sortGroup === 'alphabetical' ? 'asc' : 'desc';
                    chartListData = chartListData.map((chartData: any) => {
                        if (!chartData.data) {
                            return chartData;
                        }

                        const data = chartData.data;

                        if (data.hasOwnProperty('constructorType')) {
                            chartData.constructorType = data.constructorType;
                        }

                        switch (this.sortGroup) {
                            case 'alphabetical':
                                // Use the chart title
                                chartData.sortKey = data.elementName;
                                break;

                            case 'characteristicScore':
                            // case 'topBinIndexScore':
                            default:
                                let binScore = 0;
                                switch (data.rawData[0].dataElementType || '') {
                                    case ReportUtils.dataElementTypes.numerical:
                                    case ReportUtils.dataElementTypes.index:
                                        binScore = ReportUtils.decileWeightedIndexScore({statsDetail: data.rawData});
                                        // console.debug(`🔵 SCORED BIN: ${binScore}`, data.rawData, chartData);
                                        chartData.sortKey = binScore;
                                        break;

                                    default:
                                        // Use the HIGHEST index found across all bins
                                        binScore = Math.max(...data.rawData.map((detail: any) => detail.indexScore));

                                        // Create a sliding score from all bins
                                        // const binLength = data.rawData.length;
                                        // binScore = 0;
                                        // data.rawData.forEach((detail: any, index: number) => {
                                        //     binScore += (binLength - index) * detail.indexScore;
                                        // });
                                        console.debug(`🟠 NON-SCORED BIN: ${binScore}`, data.rawData, chartData);
                                        chartData.sortKey = binScore;
                                }
                                // chartData.sortKey = chartData.data.rawData[0];
                                break;

                            case 'linearityLiftScore':
                                chartData.sortKey = data.linearityLiftScore;
                                break;
                        }

                        return chartData;
                    });

                    chartListData = Utils.sortByProperty(chartListData, 'sortKey', sortDirection);
                }
                    break;
            }
        }

        return chartListData;
    }

    get clientName() {
        return this.reportData?.client?.divisionName;
    }

    get createdDate() {
        return this.reportData?.report?.reportCreationDate;
    }

    get currentTab(): NavLink {
        return this.subnavLinks.links.find((navLink: NavLink) => navLink.params?.tab === this.params.tab);
    }

    get currentSection(): NavLink | null {
        const tab: NavLink = this.currentTab;
        if (!tab.hasOwnProperty('links')) {
            return null;
        }

        return tab.links!.find((link: NavLink) => (link.params?.section || false) === this.params.section);
    }

    get filterAlertClass(): string[] {
        let classes = ['alert', 'alert-sm'];
        classes.push(this.filterApplied ? 'alert-warning' : 'alert-info');

        return classes;
    }

    get filterApplied(): boolean {
        if (!this.pageMetadata) {
            return false;
        }

        if (this.isSummaryPage) {
            // Summary charts already determine whether a filter is applied
            return this.chartListData[0]?.data?.filterApplied || false;
        }

        // If the current page has fewer elements than the API metadata, filters are active
        // By this point, the page should already be filtering out items based on INDEX toggle
        const sourceElementCount = this.pageMetadata.currentCategory.dataElements.length;
        let pageElements = this.currentSection.detail.metadata.dataElements;
        const visibleElementCount = pageElements.length;
        // console.debug(
        //     `Checking for filter\r\n -> VISIBLE ELEMENTS: ${visibleElementCount}\r\n -> SOURCE ELEMENT COUNT: ${sourceElementCount}`,
        //     pageElements
        // );

        return visibleElementCount < sourceElementCount;
    }

    get filterStatusMessage(): string {
        return this.filterApplied ?
            `Filters are applied.` :
            `No filters applied.`;
    }

    // get hasExcludedDataElements(): boolean | null {
    //     // if (!this.isSummaryPage) {
    //     //     return null;
    //     // }
    //
    //     return this.settings.thresholdCount.value > 0
    //         || this.settings.thresholdPercent.value > 0;
    // }

    // get hasFilteredDataElementType(): boolean | null {
    //     // TODO: expand for other data types?
    //     if (this.settings['filterDataElementType.index']?.value === true) {
    //         return true;
    //     }
    //
    //     return false;
    // }

    get hasLinearityAndLiftScoreData(): boolean {
        // return false;
        return this.chartListData.some(chartData => (chartData.data?.linearityLiftScore || null) !== null);
        // return this.chartList.some(chartId => chartId.indexOf(':map:') > -1);
    }

    get hasMapData(): boolean {
        return this.chartList.some(chartId => chartId.indexOf(':map:') > -1);
    }

    get hasPageLevelCartExport(): boolean {
        return this.params.section !== 'summary' && this.pageLevelDataElementIds.length > 0;
    }

    get headerExtra(): string {
        let headerExtra = [];
        headerExtra.push(`<strong>Created ${Utils.dateFormat(this.createdDate, {format: 'fullEnglishDate'})} for ${this.clientName}</strong>`);
        headerExtra.push(`<em class="text-size-md">File: ${this.reportData?.file?.appendFileName}</em>`);
        headerExtra.push(`<em class="text-size-md">Total records processed: ${this.reportData?.file?.inputRecords.toLocaleString()}</em>`);

        return `<div class="text-right">${headerExtra.map(item => `<div>${item}</div>`).join('')}</div>`;
    }

    get isAutomatedPage(): boolean {
        return this.currentTab?.detail?.automated || false;
    }

    get isSummaryPage(): boolean {
        return this.params.tab === undefined || this.params.section === 'summary';
    }

    get layoutColumns() {
        if (this.hasMapData) {
            return -1; // Full width column
        }

        if (this.isAutomatedPage) {
            // Dynamically driven via page metadata
            switch (this.params.section) {
                case 'summary':
                    return -1; // Full width column
            }
        }

        return 2;
    }

    get metadataPages() {
        return Utils.sortByProperty(this.reportData.pages, 'tabSequence', 'asc');
    }

    get onPageHelpContent(): any | boolean {
        const pageMetadata = this.pageMetadata;
        if (pageMetadata === false || pageMetadata.currentCategory.pageHelpText === null) {
            return false;
        }

        let pageHelpText = clone(pageMetadata.currentCategory.pageHelpText);
        for (const key of Object.keys(pageHelpText)) {
            pageHelpText[key] = pageHelpText[key].map(text => {
                return text
                    .replaceAll('{{ sectionTitle }}', this.currentSection?.detail.metadata.categoryDescription || 'UNKNOWN');
            });
        }

        return pageHelpText;
    }

    get pageLevelDataElementIds() {
        let dataElementIds = [];
        for (const chartData of this.chartListData) {
            const chart = chartData.data || false;
            if (!chart) {
                continue;
            }

            if (chart.hasOwnProperty('elementIds')) {
                // Multiple IDs
                dataElementIds = dataElementIds.concat(chart.elementIds);
            } else if (chart.hasOwnProperty('elementId')) {
                dataElementIds.push(chart.elementId);
            } else {
                for (const element of chart.rawData || []) {
                    if (dataElementIds.indexOf(element.dataElementId) === -1) {
                        dataElementIds.push(element.dataElementId);
                    }
                }
            }
        }

        return dataElementIds;
    }

    get pageMetadata(): any | boolean {
        const currentPage = this.metadataPages.find(page => Utils.slug(page.pageDescription) === this.params.tab);
        if (currentPage) {
            const currentCategory = currentPage.categories.find(category => Utils.slug(category.categoryDescription) === this.params.section);
            if (currentCategory) {
                return {
                    currentPage,
                    currentCategory,
                }
            }
        }

        return false;
    }

    get printMode(): boolean {
        return this.$route.name === 'reportPDF';
    }

    get sectionKey() {
        let sectionKey = this.params.tab;
        if (this.params.section) {
            sectionKey += `-${this.params.section}`;
        }

        return sectionKey;
    }

    get sectionTitle() {
        let title = '';

        if (this.isAutomatedPage) {
            // Dynamically driven via page metadata
            const tab = this.currentTab;
            title = tab.label;

            const section = this.currentSection;
            if (section) {
                title += `: ${section.detail.metadata.categoryDescription || 'UNKNOWN'}`;
            }

            return title;
        }

        return title;
    }

    get sortGroupOptions() {
        if (this.isAutomatedPage) {
            // Dynamically driven via page metadata

            if (this.hasMapData) {
                this.sortGroup = this.sortGroup || 'indexScore';
                return [
                    Object.assign({}, Object.assign({}, sortIndexElement, {label: 'Show Index'}), {group: 'indexScore'}),
                    Object.assign({}, Object.assign({}, sortQuantityElement, {label: 'Show Percentage'}), {group: 'overlayPercent'}),
                    // Object.assign({}, sortZScoreElement, {group: 'topBinOverlayZScore'}),
                ];
            }

            switch (this.params.section) {
                case 'summary':
                    // TODO: expand to other dynamic page-driven summaries?
                    // this.sortGroup = this.sortGroup || 'topBinIndexScore';
                    this.sortGroup = this.sortGroup || 'characteristicScore';

                    return [
                        // Object.assign({}, sortIndexElement, {group: 'topBinIndexScore'}),
                        Object.assign({}, sortIndexElement, {group: 'characteristicScore'}),
                        // Object.assign({}, sortQuantityElement, {group: 'topBinOverlayNumberOfIndividuals'}),
                        Object.assign({}, sortQuantityElement, {group: 'relatedOverlayNumberOfIndividuals'}),
                        // Object.assign({}, sortZScoreElement, {group: 'topBinOverlayZScore'}),
                    ];

                default: {
                    // this.sortGroup = this.sortGroup || 'topBinIndexScore';
                    this.sortGroup = this.sortGroup || 'characteristicScore';
                    let sortGroupOptions = [
                        // Object.assign({}, sortIndexElement, {group: 'topBinIndexScore'}),
                        Object.assign({}, sortIndexElement, {group: 'characteristicScore'}),
                        sortAlphabeticalElement,
                    ];
                    if (this.hasLinearityAndLiftScoreData) {
                        sortGroupOptions.push(sortLiftScoreElement);
                    }

                    return sortGroupOptions;
                }
            }
        }

        return [];
    }

    get subnavLinks(): NavElement {
        const routeName: string = this.$route.name;
        let navLinks: NavElement = {
            placement: 'page',
            links: [
                {
                    label: 'Executive Overview',
                    name: routeName,
                    default: true
                },
            ]
        };

        // Add dynamic pages from metadata
        for (const page of this.metadataPages) {
            const tab = Utils.slug(page.pageDescription);
            let link: NavLink = {
                id: tab,
                detail: {
                    automated: true,
                },
                label: page.pageDescription,
                name: routeName,
                params: {tab},
            }

            if ((page.categories || []).length) {
                // Handle menu items
                link.links = [];
                for (const sourceCategory of Utils.sortByProperty(page.categories, 'displaySequence', 'asc')) {
                    let category = clone(sourceCategory); // MUST be cloned to avoid filtering out the source record!
                    // Filter data elements according to user preferences
                    category.dataElements = category.dataElements.filter((elementInfo: any) => {
                        if (this.settings['filterDataElementType.index']?.value === true) {
                            // Do not display INDEX data elements
                            const dataElement = this.dataElementById(elementInfo.dataElementDisplayId);
                            return dataElement?.dataElementType !== dataElementTypes.index;
                        }

                        return true;
                    });
                    if (!category.dataElements.length) {
                        continue;
                    }

                    let label: string = category.categoryDescription;
                    const section = Utils.slug(label);
                    if (label !== 'Summary' && category.dataElements?.length) {
                        label = `${label} <span class="badge badge-pill badge-info align-text-top">${category.dataElements.length}</span>`;
                    }
                    link.links?.push({
                        detail: {
                            metadata: category
                        },
                        label,
                        // count,
                        name: routeName,
                        params: {
                            tab,
                            section,
                        },
                    });
                }
            }

            navLinks.links.push(link);
        }

        // Export cart
        let exportCart: NavLink = {
            id: 'order',
            detail: {
                // automated: true,
                tooltip: `${this.exportCart.dataElements.length || 'No'} item${this.exportCart.dataElements.length > 1 ? 's' : ''} ready to export for ordering`,
            },
            icon: ['fas', 'shopping-cart'],
            // label: `<i class="fas fa-shopping-cart fa-fw"></i> <span class="badge badge-pill badge-info align-middle">${this.exportCart.dataElements.length}</span>`,
            label: `<span class="badge badge-pill badge-info align-middle">${this.exportCart.dataElements.length}</span>`,
            name: routeName,
            params: {
                tab: 'order'
            },
        }
        navLinks.links.push(exportCart);

        // Settings
        let settings: NavLink = {
            id: 'features',
            // detail: {
            //     tooltip: `Report settings`,
            // },
            // label: `<i class="fas fa-bars fa-fw"></i>`,
            icon: ['fas', 'bars'],
            name: routeName,
            // params: {
            //     tab: 'settings'
            // },
            links: [
                {
                    icon: ['fad', 'cogs'],
                    label: 'Settings',
                    name: routeName,
                    params: {
                        tab: 'settings',
                    },
                },
                {
                    // label: 'Individual and Family',
                    icon: ['fad', 'books'],
                    label: `Data Guide`,
                    name: routeName,
                    params: {
                        tab: 'data-guide',
                    },
                },
            ],
        }
        navLinks.links.push(settings);

        return navLinks;
    }

    associatedDataTableActionMode(dataElementIds: string | string[]): string {
        if (typeof dataElementIds === 'string') {
            dataElementIds = [dataElementIds]
        }
        const cartDataElementIds = this.exportCart.dataElements.map((element: any) => element.dataElementId);
        const allDataElementsInCart = dataElementIds.every((dataElementId: string) => cartDataElementIds.includes(dataElementId));

        return allDataElementsInCart ? 'remove' : 'add';
    }

    associatedDataTableActionParameters(dataElementIds: string[]): any {
        const dataElements = dataElementIds
            // .map(dataElementId => structuredClone(this.reportData.report.stats.find(element => element.dataElementId === dataElementId) || {}))
            .map(dataElementId => structuredClone(this.dataElementById(dataElementId) || {}))
            .filter(element => element.hasOwnProperty('dataElementId'));
        // console.debug(`DATA ELEMENT IDS: ${JSON.stringify(dataElementIds)}`, dataElements);

        // console.debug('ASSOCIATED DATA TABLE ACTION:', event);
        // const iconVariant = 'fa-star';
        // const iconVariant = 'fa-cart-plus';
        let icon: string[];
        let tooltip: string;

        const mode = this.associatedDataTableActionMode(dataElementIds);
        const dataElementDescription = dataElementIds.length <= 10 ?
            dataElementIds.join(', ') :
            `these ${dataElementIds.length} data elements`;
        if (mode === 'remove') {
            // icon = ['fas', iconVariant];
            icon = ['fad', 'fa-trash'];
            tooltip = `Remove ${dataElementDescription} from your MarketSignals order`;
        } else {
            // icon = ['far', iconVariant];
            icon = ['far', 'fa-cart-plus'];
            tooltip = `Select ${dataElementDescription} for a MarketSignals order`;
        }

        return {
            event: 'click',
            html: `<a href><i class="${icon.join(' ')} fa-lg fa-fw"></i></a>`,
            mode,
            params: {
                dataElements,
                // dataElementId,
                // dataElementName,
            },
            tooltip,
        };
    }

    associatedDataTableActionHandler(event: any) {
        // const dataElementId = event.params.dataElementId;
        const dataElements = event.params.dataElements;
        const dataElementIds = dataElements.map(element => element.dataElementId);
        const mode = this.associatedDataTableActionMode(dataElementIds);
        // console.debug(`ASSOCIATED DATA TABLE HANDLER -> ${mode} ${dataElementIds.join(', ')}:`, event);
        if (mode === 'remove') {
            const updatedDataElements = this.exportCart.dataElements.filter((dataElement: any) => !dataElementIds.includes(dataElement.dataElementId));
            Vue.set(this.exportCart, 'dataElements', updatedDataElements);
            // console.debug(`ITEMS ${dataElementIds.join(', ')} REMOVED - NEW DATA ELEMENTS LIST:`, updatedDataElements);
        } else {
            let updatedDataElements = Array.from(this.exportCart.dataElements);
            for (const dataElement of dataElements) {
                if (!updatedDataElements.find((element: any) => element.dataElementId === dataElement.dataElementId)) {
                    updatedDataElements.push(structuredClone(dataElement));
                }
            }
            Vue.set(this.exportCart, 'dataElements', updatedDataElements);
        }

        this.handleCustomLayout();
    }

    changeSetting(event: any) {
        const context = event.context || 'value';
        this.settings[event.setting][context] = event.value;
        const isJSON = Array.isArray(this.settings[event.setting][context])
            || typeof this.settings[event.setting][context] === 'object';

        switch (event.setting) {
            case 'filterDataElementType.index':
                // Possible chart cache concern - flush now
                this.clearChartDataCache();
                this.chartListCache = [];
                break;
        }

        const storedValue = isJSON ? JSON.stringify(event.value) : event.value;
        window.localStorage.setItem(`settings.${event.setting}`, storedValue);
    }

    chartActionHandler(event: any) {
        switch (event.detail.action) {
            case chartEvents.excludeDataElement:
                const context = event.detail.context,
                    dataElementId = event.detail.dataElementId;
                let value = structuredClone(this.settings[`excludedDataElements.${context}`].value);
                if (value.indexOf(dataElementId) === -1) {
                    value.push(dataElementId);
                    this.changeSetting({
                        setting: `excludedDataElements.${context}`,
                        value,
                    });

                    // Refresh chart(s)
                    this.clearChartDataCache(event.detail.chartId);

                    if (this.customLayout) {
                        this.handleCustomLayout();
                    }
                }
                break;

            default:
                console.error(`No handler for chart event "${event.detail.action}"...`, event);
        }
    }

    /**
     * Get ChartAssociatedData parameters
     *
     * @param associatedId
     */
    chartAssociatedData(associatedId: string) {
        switch (associatedId) {
            case (associatedId.match(ReportUtils.dynamicElementPattern) || {}).input: {
                const [, , , , dataElementId] = associatedId.match(ReportUtils.dynamicElementPattern);
                const chartData = this.chartData(associatedId);
                if (!chartData) {
                    return chartData;
                }

                const seriesData = chartData.rawData; // TODO: replace with rawData Excel structure?
                const isBinnedData = ReportUtils.hasBinnedData({statsDetail: seriesData});
                // const isDecileData = seriesData[0].dataElementType === ReportUtils.dataElementTypes.numerical
                //     && seriesData.length === ReportUtils.decileBinDescriptions.length;

                let columns = [seriesData[0].dataElementDescriptor || ReportUtils.dataElementDescriptor, 'Quantity', ReportUtils.percentOverlayDescriptor, ReportUtils.percentBaselineDescriptor, 'Index'];
                if (seriesData.some(detail => detail.hasOwnProperty('lowValue'))) {
                    // Add low value handling
                    columns.push(ReportUtils.lowValueDescriptor);
                }
                if (seriesData.some(detail => detail.hasOwnProperty('medianValue'))) {
                    // Add median value handling
                    columns.push(ReportUtils.medianValueDescriptor);
                }
                // if (seriesData.some(detail => detail.hasOwnProperty('overlayZScore'))) {
                //     // Add Z-Score handling
                //     columns.push(zScoreDescriptor);
                // }
                let defaultSortColumn = 'Index';
                if (/*isDecileData ||*/ isBinnedData) {
                    const binColumn = /*isDecileData ? 'Decile' : */'#';
                    columns.splice(1, 0, binColumn);
                    defaultSortColumn = binColumn;
                }

                const data = seriesData.map(detail => {
                    const columnData = [
                        // detail.description,
                        detail.description,
                        detail.overlayNumberOfIndividuals.toLocaleString(),
                        Utils.formatValue(detail.hasOwnProperty('overlayPercent') ? detail.overlayPercent : detail.overlayValue, 'percent'),
                        Utils.formatValue(detail.hasOwnProperty('baselinePercent') ? detail.baselinePercent : detail.baselineValue, 'percent'),
                        Utils.formatValue(detail.hasOwnProperty('indexScore') ? detail.indexScore : detail.index, 'decimal', 2),
                    ];
                    if (columns.indexOf(ReportUtils.lowValueDescriptor) > -1) {
                        const lowValue = detail.lowValue === null ?
                            `${(detail.lowValueOriginal || 0) < 0 ? '-' : ''}${ReportUtils.infinityDescriptor}` :
                            Utils.formatValue(detail.lowValue || 0, 'decimal', 2);
                        columnData.push(lowValue);
                    }
                    if (columns.indexOf(ReportUtils.medianValueDescriptor) > -1) {
                        columnData.push(Utils.formatValue(detail.medianValue || 0, 'decimal', 2));
                    }
                    // if (columns.indexOf(zScoreDescriptor) > -1) {
                    //     columnData.push(Utils.formatValue(detail.overlayZScore, 'decimal', 2));
                    // }
                    if (isBinnedData /*|| isDecileData*/) {
                        columnData.splice(1, 0, detail.binId);
                    }

                    return columnData;
                });

                let subtitle: string | null = null;
                if ((chartData.linearityLiftScore || null) !== null) {
                    subtitle = `${ReportUtils.linearityLiftScoreDescriptor}: ${Utils.formatValue(chartData.linearityLiftScore, 'decimal', 4)}`;
                }

                const elementIds = chartData.elementIds || [dataElementId];

                return {
                    detail: [
                        {
                            id: `${associatedId}:index`,
                            title: `${dataElementId} ${chartData.elementName}`,
                            subtitle,
                            tableAction: this.associatedDataTableActionParameters(elementIds),
                            columns,
                            data,
                            sort: {column: defaultSortColumn, direction: 'desc'},
                            wrapperClass: 'col-12',
                        },
                    ]
                };
            }

            // CoCo Scores, Spending Preferences, etc - driven by page metadata
            case (associatedId.match(ReportUtils.dynamicSummaryPattern) || {}).input: {
                const [, pageSlug, sectionSlug] = associatedId.match(ReportUtils.dynamicSummaryPattern);
                const chartData = this.chartData(associatedId);
                if (!chartData) {
                    return chartData;
                }

                const pageData = this.pageMetadata.currentPage;
                // const pageData = this.metadataPages.find(page => Utils.slug(page.pageDescription) === pageSlug);

                // const data = Utils.sortByProperty(chartData.rawData, 'maxIndexScore', 'asc')
                // const data = Utils.sortByProperty(chartData.rawData, 'topBinIndexScore', 'asc')
                const data = chartData.rawData
                    .map((element: any) => {
                        // const topBin: any = element.statsDetail.find(detail => detail.binId === 10);
                        const detail: any = element.statsDetail.find((detail: any) => detail.indexScore === element.displayIndexScore);
                        return [
                            element.dataElementId,
                            element.shortDescription,
                            Utils.formatValue(detail.overlayPercent, 'percent'),
                            Utils.formatValue(detail.baselinePercent, 'percent'),
                            Utils.formatValue(detail.indexScore || 0, 'decimal', 2),
                            // topBin.overlayNumberOfIndividuals.toLocaleString(),
                            detail.overlayNumberOfIndividuals.toLocaleString(),
                            Utils.formatValue(detail.medianValue || 0, 'decimal', 2),
                            // Utils.formatValue(topBin.overlayZScore || 0, 'decimal', 2),
                        ]
                    });

                return {
                    detail: [
                        {
                            id: associatedId,
                            title: `${pageData.pageDescription} Summary`,
                            columns: [
                                'ID',
                                ReportUtils.dataElementDescriptor,
                                ReportUtils.percentOverlayDescriptor,
                                ReportUtils.percentBaselineDescriptor,
                                'Index',
                                'Quantity',
                                ReportUtils.medianValueDescriptor,
                                // zScoreDescriptor,
                            ],
                            data,
                            sort: {column: 'Index', direction: 'desc'},
                        },
                    ]
                };
            }

            default:
                return false;
        }
    }

    chartAssociatedId(chartId: string) {
        switch (chartId) {
            // case 'demographics_summary':
            // case 'interests_summary':
            //     return chartId;

            //**** Pattern-based chart ID matches follow ****//

            // case 'household_composition':
            // case (chartId.match(ReportUtils.demographicElementPattern) || {}).input:
            case (chartId.match(ReportUtils.dynamicElementPattern) || {}).input:
            case (chartId.match(ReportUtils.dynamicSummaryPattern) || {}).input: {
                return chartId;
            }

            default:
                return false
        }
    }

    chartCacheId(chartId: string): string {
        return `${this.reportData.uuid}-${chartId}`;
    }

    /**
     * Convenience method to retrieve and cache chart data for display
     */
    chartData(chartId: string, returnData: boolean = true) {
        const cacheChartId = this.chartCacheId(chartId);

        if (!this.chartDataCache.hasOwnProperty(cacheChartId)) {
            let chartData: any;
            chartData = ReportUtils.prepareChartData({
                asyncData: this.asyncData,
                chartId,
                // fieldDictionary: this.fieldDictionary,
                filterSettings: this.filterSettings,
                Highcharts,
                // insights: this.insights,
                params: this.params,
                reportData: this.reportData,
                returnData,
                settings: this.settings,
                sortGroup: this.sortGroup,
            });
            if (!(chartData.cache || true)) {
                return chartData;
            }
            Vue.set(this.chartDataCache, cacheChartId, chartData);
        }

        return this.chartDataCache[cacheChartId];
    }

    clearChartDataCache(chartId?: string) {
        if (chartId) {
            const cacheId = this.chartCacheId(chartId);
            if (!this.chartDataCache.hasOwnProperty(cacheId)) {
                return;
            }

            Vue.delete(this.chartDataCache, cacheId);
        } else {
            Vue.set(this, 'chartDataCache', {});
        }
    }

    dataElementById(dataElementId: string): any | null {
        return this.reportData.report.stats.find((element: any) => element.dataElementId === dataElementId) || null;
    }

    deleteAllCartItems() {
        Vue.set(this.exportCart, 'dataElements', []);
        this.handleCustomLayout();
    }

    deleteCartItem(dataElement: any) {
        // TODO: deprecate this code, replacing with handleCartItem()
        this.associatedDataTableActionHandler({
            params: {
                dataElements: [dataElement],
            }
        });
    }

    handleCartItem(params: any) {
        this.associatedDataTableActionHandler({params});
    }

    async excelExport() {
        let clientName = this.clientName.replaceAll(/[\s\/]+/g, '_').replaceAll(/[^a-zA-Z0-9_]+/g, '');
        let fileName = `${Utils.dateFormat(new Date(), {format: 'isoShort'})}_${clientName}_PDA_${this.params.tab}_${this.params.section}`;
        let options: any = {
            // columns, // (optional) column widths, etc.
            fileName
        };

        let data = [];
        let sheets = [];
        // let columns = [];
        const dataTables: HTMLTableElement[] = Array.from(document.querySelectorAll(`table[data-intent="${dataTableIndicator}"][data-allow-export="true"]`));
        for (const dataTable of dataTables) {
            // Table title -> sheet name
            const tableName = dataTable.querySelector(`[data-intent="${tableTitleIndicator}"] th`).textContent;
            // const sheetName = `${data.length + 1}) ${tableName.replaceAll(' & ', ' and ').replaceAll(/[^a-zA-Z0-9 ,]+/g, '').replaceAll(/\s+/g, ' ')}`; // Remove invalid characters and add index
            const sheetName = `${tableName.replaceAll(' & ', ' and ').replaceAll(/[^a-zA-Z0-9 ,]+/g, '').replaceAll(/\s+/g, ' ')}`; // Remove invalid characters
            sheets.push(sheetName);

            // Translate table rows into arrays
            const tableRows = dataTable.querySelectorAll(`tr:not([data-intent="${tableTitleIndicator}"]):not([data-intent="${tableSubtitleIndicator}"])`);
            let tableData = [];
            let tableSpan = 1;

            let rowIndex = 0;
            for (const row of tableRows) {
                const cellData = Array.from(<HTMLTableCellElement[]>row.querySelectorAll('td, th'))
                    .map(tableCell => {
                        let cellContent: any = {
                            value: tableCell.textContent,
                        };
                        if (rowIndex === 0) {
                            // TODO: convert to using schema settings instead of inline formatting?
                            cellContent.fontWeight = 'bold';
                        }

                        return cellContent;
                    });
                tableSpan = Math.max(cellData.length, tableSpan);
                tableData.push(cellData);
                ++rowIndex;
            }

            const tableSubtitle = dataTable.querySelector(`[data-intent="${tableSubtitleIndicator}"] th`)?.textContent;
            if (tableSubtitle) {
                tableData.unshift([{
                    align: 'center',
                    fontSize: '10',
                    // fontWeight: 'bold',
                    span: tableSpan,
                    value: tableSubtitle,
                }]);
            }

            data.push(tableData);
        }
        options.sheets = sheets.map(sheetName => sheetName.substring(0, 31));

        await writeXlsxFile(data, options);
    }

    getDataElementSearchResults() {
        this.dataElementSearchResult = [];

        // Find the matching element...
        const searchString = this.dataElementSearchQuery.toLowerCase();
        if (searchString.length >= 3) {
            // const matchCount =
            const elementsMatched = Utils.sortByProperty(
                this.reportData.report.stats,
                'dataElementId',
                'asc'
            )
                .filter((element: any) => {
                    return element.dataElementId.toLowerCase().indexOf(searchString) > -1
                        || element.shortDescription.toLowerCase().indexOf(searchString) > -1
                });
            if (elementsMatched) {
                for (const dataElement of elementsMatched) {
                    // Find which pages this element is on, if any
                    const pages = Utils.dataElementPageLocations(
                        this.metadataPages,
                        dataElement.dataElementId,
                        Object.assign({}, this.params, {routeName: 'pdaReport'})
                    );

                    this.dataElementSearchResult.push({
                        dataElement,
                        pages,
                    });
                }
            }
        }
    }

    async handleCustomLayout() {
        this.customLayout = false;

        switch (this.$route.name) {
            /* Route-based custom layouts */
        }

        if (!this.customLayout) {
            switch (this.params.tab) {
                case undefined: // Executive Overview (default tab)
                    let overviewData = [
                        {
                            chartId: 'main_summary_characteristics_all:categorical:desc',
                            data: this.chartData('main_summary_characteristics_all:Categorical:desc'),
                        },
                        {
                            chartId: 'main_summary_characteristics_all:numerical,index:desc',
                            data: this.chartData('main_summary_characteristics_all:Numerical,Index:desc'),
                        },
                        {
                            chartId: 'main_summary_characteristics_all:categorical:asc',
                            data: this.chartData('main_summary_characteristics_all:Categorical:asc'),
                        },
                        {
                            chartId: 'main_summary_characteristics_all:numerical,index:asc',
                            data: this.chartData('main_summary_characteristics_all:Numerical,Index:asc'),
                        },
                        // {
                        //     chartId: 'main_summary_characteristic:demographics',
                        //     data: this.chartData('main_summary_characteristic:demographics'),
                        // },
                        // {
                        //     chartId: 'main_summary_characteristic:interests',
                        //     data: this.chartData('main_summary_characteristic:interests'),
                        // },
                    ]
                    // Add in dynamic charts
                    for (const page of this.metadataPages) {
                        // Skip map chart types - TODO: replace with more manageable metadata
                        if (page.categories.every((category: any) => category.dataElements.every((element: any) => element.chartType.toLowerCase() === 'map'))) {
                            continue;
                        }
                        const chartId = `main_summary_characteristic:${Utils.slug(page.pageDescription)}`;
                        overviewData.push({
                            chartId,
                            data: this.chartData(chartId),
                        })
                    }

                    // const detail = [
                    //     // {
                    //     //     chartId: 'main_summary_file_analysis',
                    //     //     associatedId: 'main_summary_file_analysis:ASSOC',
                    //     //     data: {
                    //     //         detail: `<strong>Total Records: ${this.reportData.file.inputRecords.toLocaleString()}</strong>`,
                    //     //         format: 'html',
                    //     //     },
                    //     // },
                    //     // {},
                    // ];

                    Vue.set(this, 'customLayout', {
                        component: PdaSummary,
                        chartData: {
                            // filterStatus: {
                            //     alertClass: this.filterAlertClass,
                            //     message: this.filterStatusMessage,
                            // },
                            fileAnalysis: [
                                {
                                    chartId: 'main_summary_file_analysis',
                                    data: this.chartData('main_summary_file_analysis'),
                                },
                                {
                                    chartId: 'main_summary_match_rate',
                                    data: this.chartData('main_summary_match_rate'),
                                },
                            ],
                            overviewData
                        },
                        detail: [],
                        // detail,
                    })
                    break;

                case 'data-guide': {
                    Vue.set(this, 'customLayout', {
                        component: DataGuide,
                        chartData: {
                            test: true,
                            cartHandlers: {},
                        },
                        // chartData: {},
                        // detail: {},
                        detail: this.reportData,
                        params: this.params,
                    })
                }
                    break;

                case 'order':
                    Vue.set(this, 'customLayout', {
                        component: ExportCart,
                        chartData: {},
                        detail: this.exportCart.dataElements,
                    })
                    break;

                case 'settings':
                    Vue.set(this, 'customLayout', {
                        component: Settings,
                        // chartData: {},
                        // detail: {},
                        detail: this.reportData,
                        params: this.settings,
                    })
                    break;

                default:
                    this.customLayout = false;
            }
        }
    }

    /**
     * Functions to run on "page load"-whether via mounted() or inline route change
     * @param isInitial
     */
    async initialize(isInitial: boolean = false) {
        this.reportDataReady = false;
        this.clearChartDataCache();

        this.params = this.$route.params;
        this.filterSettings.count = 0;
        this.sortGroup = null; // Reset sort group elements
        const sgo = this.sortGroupOptions; // Call once to ensure default settings are ready

        if (isInitial) {
            this.restoreSettings();
        }

        await this.prepareAsyncData(isInitial);
        if (this.error.code) {
            return;
        }
        await this.handleCustomLayout();
        if (this.error.code) {
            return;
        }

        if (isInitial) {
            // Check local storage for saved export cart selections
            this.restoreSavedExportCartSelections();
            this.reportReady = true;
        }
        this.reportDataReady = true;
    }

    keyListener(event: KeyboardEvent) {
        if (['?', '/'].includes(event.key) && event.ctrlKey && (event.shiftKey || event.metaKey || event.altKey)) {
            // Show data element search modal
            this.$bvModal.show(this.dataElementSearchModal);
        }
    }

    pageLevelCartExport() {
        const dataElementIds = this.pageLevelDataElementIds;
        const mode = this.associatedDataTableActionMode(dataElementIds);

        if (mode === 'remove') {
            const updatedDataElements = this.exportCart.dataElements.filter((dataElement: any) => !dataElementIds.includes(dataElement.dataElementId));
            Vue.set(this.exportCart, 'dataElements', updatedDataElements);
        } else {
            const dataElements = dataElementIds
                .filter(dataElementId => !this.exportCart.dataElements.find((cartElement: any) => cartElement.dataElementId === dataElementId))
                .map(dataElementId => this.reportData.report.stats.find(dataElement => dataElement.dataElementId === dataElementId));
            let updatedDataElements = Array.from(this.exportCart.dataElements);
            for (const dataElement of dataElements) {
                updatedDataElements.push(structuredClone(dataElement));
            }
            Vue.set(this.exportCart, 'dataElements', updatedDataElements);
        }

        this.handleCustomLayout();
    }

    async prepareAsyncData(initial: boolean = false) {
        this.asyncData = [];

        if (initial) {
            await this.retrieveReportData();
        }

        if (this.error.code) {
            return;
        }

        // Retrieve any asynchronous data necessary for the active report page
        const forLoop = async () => {
            for (const chartId of this.chartList) {
                // if (chartId.indexOf(NON_CHART_PREFIX) === -1) {
                const chartProperties = {
                    asyncData: this.asyncData,
                    chartId,
                    // fieldDictionary: this.fieldDictionary,
                    filterSettings: this.filterSettings,
                    Highcharts,
                    // insights: this.insights,
                    params: this.params,
                    reportData: this.reportData,
                    returnData: false,
                    sortGroup: this.sortGroup,
                };
                const chartInfo = ReportUtils.prepareChartData(chartProperties);
                if (typeof chartInfo === 'object' && chartInfo.asyncData !== undefined) {
                    const asyncData = await chartInfo.asyncData();

                    // If any data is returned, store it for later use
                    forOwn(asyncData, (data, key) => {
                        this.asyncData[key] = data;
                    });
                }
                // }
            }
        };
        await forLoop();

        return true;
    }

    async prepareForOrder() {
        let clientName = this.clientName.replaceAll(/[\s\/]+/g, '_').replaceAll(/[^a-zA-Z0-9_]+/g, '');
        let fileName = `${clientName}_Wiland_View_Data_Order_PDA_Selection_${Utils.dateFormat(new Date(), {format: 'standard'})}`;
        let options: any = {
            sheets: [`Data elements`],
            // columns, // (optional) column widths, etc.
            fileName
        };

        let data = [];
        let tableData: any[] = [
            [
                {value: 'Data Element ID', fontWeight: 'bold'},
                {value: 'Data Element Name', fontWeight: 'bold'},
            ]
        ];
        for (const dataElement of this.exportCart.dataElements) {
            tableData.push([
                {value: dataElement.dataElementId},
                {value: dataElement.shortDescription},
            ]);
        }
        data.push(tableData);

        await writeXlsxFile(data, options);
    }

    resetDataElementSearch() {
        this.dataElementSearchQuery = '';
        this.dataElementSearchResult = [];
    }

    restoreSavedExportCartSelections() {
        let storedSelections = window.localStorage.getItem(this.exportCart.localStorageKey);
        if (!storedSelections) {
            return false;
        }
        storedSelections = JSON.parse(storedSelections);
        if (!storedSelections.hasOwnProperty(this.params.token)) {
            return false;
        }

        const dataElementIds = storedSelections[this.params.token];
        for (const dataElementId of dataElementIds) {
            const dataElement = this.reportData.report.stats.find((element: any) => element.dataElementId === dataElementId);
            if (dataElement) {
                this.associatedDataTableActionHandler({
                    params: {
                        dataElements: [dataElement],
                    }
                });
            }
        }
    }

    restoreSettings() {
        for (const settingName of Object.keys(this.settings)) {
            let settingValue = window.localStorage.getItem(`settings.${settingName}`) || this.settings[settingName].value;
            if (settingValue.length) {
                if (isNumeric(settingValue)) {
                    settingValue = +settingValue;
                } else if (Array.isArray(this.settings[settingName].value)) {
                    settingValue = JSON.parse(settingValue);
                } else if (['true', 'false'].includes(settingValue)) {
                    settingValue = settingValue === 'true';
                }

                this.settings[settingName].value = settingValue;
            }
        }
    }

    async retrieveReportData() {
        this.reportData = await this.$store.dispatch('getPdaReportData', this.params.token);

        if (!this.reportData || typeof this.reportData === 'string') {
            this.error = {
                code: 400,
                message: `
                    <div class="w-50 mx-auto">
                        <div class="row align-items-center">
                            <div class="col-auto pr-0">
                                <i class="fa fad fa-fw fa-snooze text-secondary" style="font-size: 6rem;"></i>
                            </div>
                            <div class="col text-left text-primary">
                                <p>It seems our API decided to take a break, which means some features on our site are snoozing too. Don't worry, we'll wake it up ASAP! Hang tight and enjoy a quick break yourself.</p>
                                <p class="p-0 m-0">Thanks for your patience.</p>
                            </div>
                        </div>
                    </div>
                `
            }
            // this.reportReady = false;
        } else if (this.reportData.hasOwnProperty('error') && this.reportData.error !== 0) {
            // Error, stop processing
            this.error = {code: this.reportData.error, message: this.reportData.message}
            // this.reportReady = false;
        } else {
            // Add some helpful metadata inferences
            // this.reportData.client.divisionName = `ALSAC/St. Jude Children's Research Hospital`; // TODO: REMOVE AFTER TESTING
            this.reportData.report.stats = ReportUtils.scoredCharacteristics(this.reportData.report.stats);
            this.reportData.report.stats = this.reportData.report.stats?.map((element: any) => {
                // Index calculations
                const detailIndexValues = element.statsDetail
                    .map((detail: any) => detail.indexScore)
                    .filter((val: any) => val !== null);
                element.maxIndexScore = Math.max(...detailIndexValues);
                element.minIndexScore = Math.min(...detailIndexValues);

                // Quantity calculations
                const detailCountValues = element.statsDetail
                    .map((detail: any) => detail.overlayNumberOfIndividuals)
                    .filter((val: any) => val !== null);
                element.maxOverlayNumberOfIndividuals = Math.max(...detailCountValues);
                element.minOverlayNumberOfIndividuals = Math.min(...detailCountValues);


                element.statsDetail = element.statsDetail.map((detail: any) => {
                    detail.dataElementType = element.dataElementType; // Ensure the detail also contains element type for later processing
                    return detail;
                });

                if (ReportUtils.hasBinnedData(element)) {
                    // console.debug(`ELEMENT LOW VALUE DATA - #${element.id} ${element.shortDescription} -> ${element.statsDetail[0].lowValue}`, +element.statsDetail[0].lowValue);
                    // if (isNaN(+(element.statsDetail[0].lowValue || 0))) {
                    //     console.debug(`ELEMENT LOW VALUE DATA - #${element.id} ${element.shortDescription}`, JSON.stringify(element.statsDetail));
                    // }
                    element.statsDetail = element.statsDetail.map((detail: any) => {
                        if (Math.abs(detail.lowValue) > ReportUtils.MAX_INTEGER_VALUE) {
                            detail.lowValueOriginal = +detail.lowValue;
                            detail.lowValue = null;
                        }

                        return detail;
                    });
                    const topBin: any = element.statsDetail.find((detail: any) => detail.binId === 10);
                    if (topBin) {
                        element.topBinBaselinePercent = topBin.baselinePercent;
                        element.topBinIndexScore = topBin.indexScore || 0;
                        element.topBinOverlayPercent = topBin.overlayPercent;
                        element.topBinOverlayNumberOfIndividuals = topBin.overlayNumberOfIndividuals;
                    }
                } else {
                    // Force into bins by index to allow for consistent display
                    // console.warn(`🗑 Un-binned item (NULL check: ${element.statsDetail?.some(detail => detail.indexScore === null) ? '🔴' : '🟢'}) -> ${element.dataElementId} - ${element.shortDescription}`, element);
                    let binId = 10;
                    let binnedStatsDetail: any = Utils.sortByProperty(element.statsDetail, 'indexScore', 'desc')
                        .map(element => {
                            const elementBinId = Math.max(0, binId);
                            --binId;
                            return Object.assign({}, element, {binId: elementBinId, forcedBin: true});
                        });
                    // Put the forced bins back into the element structure
                    element = Object.assign({}, element, {
                        // forcedBins: true,
                        statsDetail: Utils.sortByProperty(binnedStatsDetail, 'codedValue', 'asc')
                    })
                }

                const relatedDetail = element.statsDetail.find((detail: any) => detail.indexScore === element.displayIndexScore);
                element.relatedOverlayNumberOfIndividuals = relatedDetail?.overlayNumberOfIndividuals;

                return element;
            })

            // this.reportReady = true;
        }
    }

    // async showOnPageHelp() {
    //     this.$bvModal.show('onPageHelp');
    // }

    // updateChartAssociatedData(associatedData) {
    //     const cacheId = this.chartCacheId(associatedData.chartId);
    //     if (this.chartDataCache.hasOwnProperty(cacheId)) {
    //         // Replace the cache data with the newly updated dataset
    //         // this.chartDataCache[cacheId] = associatedData.params.data;
    //     }
    // }

    @Watch('$route.params', {initial: false})
    async onRouteParamsChanged() {
        this.reportReady = false;
        await this.initialize(false);

        this.reportReady = true;
    }

    @Watch('customDataElementList')
    onCustomDataElementListChanged() {
        if (this.customLayout?.component === DataGuide) {
            let cartHandlers: any = {};
            for (const dataElement of this.customDataElementList) {
                cartHandlers[dataElement.dataElementId] = this.associatedDataTableActionParameters([dataElement.dataElementId]);
            }
            Vue.set(this.customLayout, 'chartData', {cartHandlers});
        }
    }

    @Watch('exportCart.dataElements')
    onExportCartDataElementsChanged() {
        let existingCartStorage = JSON.parse(window.localStorage.getItem(this.exportCart.localStorageKey) || '{}');
        existingCartStorage[this.params.token] = this.exportCart.dataElements.map((element: any) => element.dataElementId);
        window.localStorage.setItem(this.exportCart.localStorageKey, JSON.stringify(existingCartStorage));
    }

    @Watch('headerExtra')
    onHeaderExtraChanged() {
        this.$emit('set-header-extra', this.headerExtra);
    }

    @Watch('sortGroup')
    @Watch('filterSettings', {deep: true})
    onFilterSettingsChanged() {
        // Handle special cases where charts need to update without re-routing
        if (this.hasMapData) {
            this.clearChartDataCache(this.chartList[0]);
            this.handleCustomLayout();
        } else {
            this.clearChartDataCache();
        }
    }

    @Watch('subnavLinks')
    onSubnavLinksChanged() {
        this.subnavTimestamp = new Date().toISOString();
    }
}
